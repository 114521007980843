<template>
<div id="Reflection">
  <div id="preloader">
    <div id="loader"></div>
    <div id="loading">Loading</div>
  </div>
  <div id="notification">Click and drag around</div>
</div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { onMounted } from 'vue';

export default {
  name: 'Reflection',
  setup () {

    onMounted(() => {

      let scene,
        camera,
        renderer,
        sizes,
        controls,
        cubeCamera,
        sphereMaterial,
        sphereMesh,
        sphereGroup;


      function init () {
        sizes = {
          width: window.innerWidth,
          height: window.innerHeight,
          get aspect () {
            return this.width / this.height;
          },
          get pixelRatio () {
            return Math.min(window.devicePixelRatio, 2);
          },
        };

        scene = new THREE.Scene();

        /**
         * Textures
         */
        const urls = [
          require('../../public/reflection/left.png'),
          require('../../public/reflection/right.png'),
          require('../../public/reflection/sky.png'),
          require('../../public/reflection/bottom.png'),
          require('../../public/reflection/back.png'),
          require('../../public/reflection/sunset.png'),
        ];
        const cubeTextureLoader = new THREE.CubeTextureLoader();
        scene.background = cubeTextureLoader
          .load(
            urls,
            () => {
              let preloader = document.getElementById('preloader');
              if (preloader) preloader.style.display = 'none';
            });

        /**
         * Cameras
         */
        camera = new THREE.PerspectiveCamera(80, sizes.aspect, 1, 500);
        camera.position.z = 6;
        scene.add(camera);

        sphereGroup = new THREE.Group();

        const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(512, {
          format: THREE.RGBFormat,
          generateMipmaps: true,
          minFilter: THREE.LinearMipmapLinearFilter,
        });

        cubeCamera = new THREE.CubeCamera(1, 100, cubeRenderTarget);
        sphereGroup.add(cubeCamera);

        /**
         * Sphere
         */
        const sphereGeometry = new THREE.SphereBufferGeometry(2, 500, 500);

        sphereMaterial = new THREE.ShaderMaterial({
          uniforms: {
            uCubeMap: { value: cubeCamera.renderTarget.texture },
          },
          vertexShader: `
            varying vec2 vUv;
            varying vec3 vViewVector;
            varying vec3 vNormal;

            void main() {
              vec4 modelPosition = modelMatrix * vec4(position, 1.0);
              vec4 viewPosition = viewMatrix * modelPosition;
              vec4 projectedPosition = projectionMatrix * viewPosition;

              vViewVector = modelPosition.xyz - cameraPosition;
              vNormal = normal;

              gl_Position = projectedPosition;
            }
          `,
          fragmentShader: `
            uniform samplerCube uCubeMap;

            varying vec3 vViewVector;
            varying vec3 vNormal;

            void main() {
              vec3 reflectedDirection = normalize(reflect(vViewVector, vNormal));
              reflectedDirection.x = -reflectedDirection.x;

              vec3 textureColor = textureCube(uCubeMap, reflectedDirection).rgb;

              gl_FragColor = vec4(textureColor, 1.0);
            }
          `,
        });

        sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphereGroup.add(sphereMesh);

        scene.add(sphereGroup);

        /**
         * Renderer
         */
        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(sizes.pixelRatio);

        let reflection = document.getElementById('Reflection');
        reflection.appendChild(renderer.domElement);

        /**
         * Controls
         */
        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;

        /**
         * Event Listeners
         */
        window.addEventListener('resize', handleWindowResize);

        render();
      }


      function handleWindowResize () {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        camera.aspect = sizes.aspect;
        camera.updateProjectionMatrix();

        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(sizes.pixelRatio);
      }


      function render () {
        controls.update();

        cubeCamera.update(renderer, scene);

        sphereMaterial.uniforms.uCubeMap.value = cubeCamera.renderTarget.texture;

        renderer.render(scene, camera);

        let reflectionDOM = document.getElementById('Reflection');
        if (reflectionDOM) requestAnimationFrame(render);
      }

      init();

    });
  },
};
</script>

<style lang="stylus" scoped>
#Reflection
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  display flex
  align-items center
  justify-content center
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  background-image linear-gradient(#fff, #f5f5fa)
  cursor pointer
  #preloader
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    display flex
    align-items center
    justify-content center
    #loader
      position relative
      width 200px
      height 200px
      margin-right 100px
      border-radius 50%
      border 2px solid transparent
      border-top-color #9370DB
      animation spin 2s linear infinite
      &:before
        content ""
        position absolute
        top 10px
        left 10px
        right 10px
        bottom 10px
        border-radius 50%
        border 2px solid transparent
        border-top-color #BA55D3
        animation spin 3s linear infinite
      &:after
        content ""
        position absolute
        top 20px
        left 20px
        right 20px
        bottom 20px
        border-radius 50%
        border 2px solid transparent
        border-top-color #FF00FF
        animation spin 1.5s linear infinite
    #loading
      position relative
      font 300 32px Montserrat
      text-transform uppercase
      letter-spacing 2px
      color #BA55D3
  #notification
    color white
    position absolute
    top 20px
    right 20px
    background rgba(0, 0, 0, 0.5)
    font 400 13px Averta
    border-radius 10px
    padding 10px 20px
    z-index 1000

@keyframes spin
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

</style>